<template>
  <div class="pageContol listWrap templateList formCom category1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
            <em>当前位置：</em>
            <a href="javascript:;">课程分类</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem ci-full">
            </div>
          </div>
          <div class="btnBox">
            <el-button class="bgc-bv" @click="openCategoryDialog(topLevelCategory)">新增顶级分类</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table :data="categoryTree" ref="refTable" @row-dblclick="expandChange" :header-cell-style="tableHeader" height="100%" size="small" style="width: 100%"
                      row-key="categoryId">
              <el-table-column prop="categoryName" label="分类名称" align="left" width="400px"></el-table-column>
              <el-table-column prop="categoryCode" label="分类码值" align="left" width="100px"></el-table-column>
<!--              <el-table-column label="显示/隐藏" align="center">-->
<!--                <template slot-scope="scope">-->
<!--                  <el-switch v-model="scope.row.usable" active-color="#ff4949" inactive-color="#c0ccda" @change="categoryChangehidden(scope.row)"/>-->
<!--                  <span style="margin-left: 5px">{{ !scope.row.usable ? '隐藏' : '显示' }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column prop="delete" label="删除标识" align="center" :formatter="isDelete" width="100px"></el-table-column>
              <el-table-column prop="categoryRemark" label="备注" align="left"></el-table-column>
              <el-table-column prop="categoryOrder" label="排序" align="left" width="100px"></el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <div slot-scope="scope" class="">
                  <el-button size="mini" type="text" @click="openCategoryEditDialog(scope.row)">编辑</el-button>
                  <el-button size="mini" type="text" @click="categoryDel(scope.row)">删除</el-button>
                  <el-button size="mini" type="text" @click="openCategoryDialog(scope.row)" v-if="scope.row.categoryLevel != '3'">新增子分类</el-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="categoryDialogTitle" :visible.sync="categoryDialogVisible" width="30%" center>
      <el-form ref="categoryDialogFromRef" :model="categoryDialogFrom" :rules="categoryDialogFromRules" label-width="120px">
        <el-form-item label="上级分类名称" prop="parentName" size hidden>
          <el-input v-model="categoryDialogFrom.parentName" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="上级分类ID" prop="parentId" size hidden>
          <el-input v-model="categoryDialogFrom.parentId" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="上级分类码值" prop="parentCode" size hidden>
          <el-input v-model="categoryDialogFrom.parentCode" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="分类级别" prop="categoryLevel" hidden>
          <el-input v-model="categoryDialogFrom.categoryLevel" show-word-limit disabled></el-input>
        </el-form-item>
<!--        <el-form-item label="显示/隐藏" prop="usable">-->
<!--          <el-switch v-model="categoryDialogFrom.usable">-->
<!--          </el-switch>-->
<!--          <span style="margin-left: 5px">{{ categoryDialogFrom.usable?'显示':'隐藏'}}</span>-->
<!--        </el-form-item>-->
        <el-form-item label="分类码值" prop="categoryCode">
          <el-input v-model="categoryDialogFrom.categoryCode" minlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')">
            <template slot="prepend">{{ categoryDialogFrom.parentCode }}</template>
          </el-input>
          <span style="font-size: 5px">数字范围为01~99，请自行观察码值规律</span>
        </el-form-item>
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model="categoryDialogFrom.categoryName" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="分类排序" prop="categoryOrder">
          <el-input v-model.number="categoryDialogFrom.categoryOrder" maxlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          <span style="font-size: 5px">数字范围为1~99，数字越小越靠前。数字相同，先添加的排序靠前</span>
        </el-form-item>
        <el-form-item label="备注" prop="categoryRemark">
          <el-input v-model="categoryDialogFrom.categoryRemark" maxlength="100" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="categoryDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="categoryAdd()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="categoryEditDialogTitle" :visible.sync="categoryEditDialogVisible" width="30%" center>
      <el-form ref="categoryEditDialogFromRef" :model="categoryEditDialogFrom" :rules="categoryEditDialogFromRules" label-width="120px">
        <el-form-item label="分类ID" prop="categoryId" hidden>
          <el-input v-model="categoryEditDialogFrom.categoryId" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="分类级别" prop="categoryLevel" hidden>
          <el-input v-model="categoryEditDialogFrom.categoryLevel" show-word-limit disabled></el-input>
        </el-form-item>
<!--        <el-form-item label="显示/隐藏" prop="usable">-->
<!--          <el-switch v-model="categoryEditDialogFrom.usable">-->
<!--          </el-switch>-->
<!--          <span style="margin-left: 5px">{{ categoryEditDialogFrom.usable?'显示':'隐藏'}}</span>-->
<!--        </el-form-item>-->
        <el-form-item label="分类码值" prop="categoryCode">
          <el-input v-model="categoryEditDialogFrom.categoryCode" minlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')">
            <template slot="prepend">{{ categoryEditDialogFrom.parentCode }}</template>
          </el-input>
          <span style="font-size: 5px">数字范围为01~99，请自行观察码值规律</span>
        </el-form-item>
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model="categoryEditDialogFrom.categoryName" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="分类排序" prop="categoryOrder">
          <el-input v-model.number="categoryEditDialogFrom.categoryOrder" maxlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          <span style="font-size: 5px">数字范围为1~99，数字越小越靠前。数字相同，先添加的排序靠前</span>
        </el-form-item>
        <el-form-item label="备注" prop="categoryRemark">
          <el-input v-model="categoryEditDialogFrom.categoryRemark" maxlength="100" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="categoryEditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="categoryEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";

export default {
  name: "mini/category",
  components: {
  },
  mixins: [List],
  data() {
    return {
      isClearable: true,      // 可清空（可选）
      isAccordion: true,      // 可收起（可选）
      props: {
        value: 'categoryId',
        label: 'categoryName',
        children: 'children',
        // disabled:true
      },
      searchCategoryId: "",
      categoryDialogVisible: false,
      categoryDialogTitle: "新增分类",
      categoryEditDialogVisible: false,
      categoryEditDialogTitle: "编辑分类",
      categoryDialogFrom: {
        parentId: "", //父级ID
        parentName: "", //父级分类名称
        parentCode: "", //父级分类码值
        categoryName: "", //分类名称
        categoryLevel: "", //分类级别
        categoryOrder: "", //分类排序
        categoryCode: "", //分类码值
        categoryRemark: "", //备注
        usable: false,
      },
      categoryDialogFromRules: {
        categoryName: [{required: true, message: "请输入分类名称", trigger: "blur",},],
        categoryOrder: [
          {required: true, message: "请输入分类排序", trigger: "blur",},
          {type: "number", message: "分类排序必须为数字值"},
          {validator: this.validatePass, trigger: "blur"},
        ],
        categoryCode: [
          {required: true, message: "请输入分类码值", trigger: "blur",},
          {validator: this.validatePass, trigger: "blur"},
        ],
      },
      categoryEditDialogFrom: {
        categoryId: "",
        categoryName: "", //分类名称
        categoryLevel: "", //分类级别
        categoryOrder: "", //分类排序
        categoryCode: "", //分类码值
        categoryRemark: "", //备注
        usable: false,
      },
      categoryEditDialogFromRules: {
        categoryName: [{required: true, message: "请输入分类名称", trigger: "blur",},],
        categoryOrder: [
          {required: true, message: "请输入分类排序", trigger: "blur",},
          {type: "number", message: "分类排序必须为数字值"},
          {validator: this.validatePass, trigger: "blur"},
        ],
        categoryCode: [
          {required: true, message: "请输入分类码值", trigger: "blur",},
          {validator: this.validatePass, trigger: "blur"},
        ],
      },
      categoryTree: [],
      categoryOptionData: [],
      topLevelCategory: {
        categoryId: 0,
        categoryName: '无--顶级分类',
        categoryCode: '',
        categoryLevel: 0
      }
    };
  },
  watch: {},
  created() {
  },
  computed: {},
  methods: {
    normalizer(node) {
      console.log(node)
      //去掉children=null的属性
      if (node.children == null || node.children == 'null') {
        delete node.children;
      }
    },
    isDelete(row, column, cellValue, index) {
      return cellValue ? "已删除" : "未删除"
    },
    expandChange(row, index, e) {
      this.$refs.refTable.toggleRowExpansion(row)
    },
    validatePass(rule, value, callback) {
      if (parseInt(value) > 99) {
        callback(new Error("最大值应在99以内"));
      } else if (parseInt(value) < 1) {
        callback(new Error("最小值应大于等于1"));
      } else {
        callback();
      }
    },
    //获取分类
    getData() {
      let params = {
        disabledLevels: "1,2"
      }
      this.$post("/miniapp/category/tree", params, 3000, true, 2)
          .then((res) => {
            if (res.status == 0) {
              this.categoryTree = res.data;
              this.categoryOptionData = res.data;
              return;
            }
            this.$message({
              type: "error",
              message: res.message ? res.message : "操作失败",
            });
          })
          .catch(() => {
            return;
          });
    },
    //添加分类接口
    categoryAdd() {
      this.$refs["categoryDialogFromRef"].validate((valid) => {
        if (valid) {
          const params = {
            parentId: this.categoryDialogFrom.parentId,
            categoryName: this.categoryDialogFrom.categoryName,
            categoryCode: this.categoryDialogFrom.parentCode + this.categoryDialogFrom.categoryCode,
            categoryLevel: this.categoryDialogFrom.categoryLevel,
            categoryOrder: this.categoryDialogFrom.categoryOrder,
            categoryRemark: this.categoryDialogFrom.categoryRemark,
            usable: this.categoryDialogFrom.usable,
          };
          this.$post(
              "/miniapp/category/insert",
              params,
              3000,
              true,
              2
          ).then((res) => {
            this.$message({
              type: res.status == "0" ? "success" : "error",
              message: res.message ? res.message : "操作失败",
            });
            if (res.status == 0) {
              this.$refs[
                  "categoryDialogFromRef"
                  ].resetFields();
              this.categoryDialogVisible = false;
              this.getData();
            }
          }).catch(() => {
            return;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加分类弹窗
    openCategoryDialog(row) {
      if (this.$refs["categoryDialogFromRef"] !== undefined) {
        this.$refs["categoryDialogFromRef"].resetFields();
      }
      this.$post(
          "/miniapp/category/initialize",
          {parentId: row.categoryId},
          3000,
          true,
          2
      ).then((res) => {
        if (res.status === "0") {
          this.categoryDialogVisible = true;
          this.categoryDialogFrom = res.data;
          this.categoryDialogTitle = "新增分类";
        } else {
          this.$message({
            type: "error",
            message: res.message ? res.message : "操作失败",
          });
        }
      }).catch(() => {
        return;
      });
    },
    // 编辑分类弹窗
    openCategoryEditDialog(row) {
      if (this.$refs["categoryEditDialogFromRef"] !== undefined) {
        this.$refs["categoryEditDialogFromRef"].resetFields();
      }
      this.categoryEditDialogVisible = true;
      this.categoryEditDialogFrom.categoryId = row.categoryId;
      this.categoryEditDialogFrom.categoryName = row.categoryName;
      this.categoryEditDialogFrom.categoryCode = row.categoryCode.substring(row.categoryCode.length - 2);//只展示后两位，目的是不让修改前几位
      this.categoryEditDialogFrom.categoryLevel = parseInt(row.categoryLevel);
      this.categoryEditDialogFrom.categoryOrder = row.categoryOrder;
      this.categoryEditDialogFrom.categoryRemark = row.categoryRemark;
      this.categoryEditDialogFrom.parentCode = row.parentCode;
      this.categoryEditDialogFrom.usable = row.usable;
      this.categoryEditDialogTitle = "编辑分类";
    },
    // 编辑分类
    categoryEdit() {
      this.$refs["categoryEditDialogFromRef"].validate((valid) => {
        if (valid) {
          // 将父类code和本次分类code拼接为最终需要的code，目的是不让修改前几位
          this.categoryEditDialogFrom.categoryCode = this.categoryEditDialogFrom.parentCode + this.categoryEditDialogFrom.categoryCode
          this.$post(
              "/miniapp/category/modify",
              this.categoryEditDialogFrom,
              3000,
              true,
              2
          ).then((res) => {
            this.$message({
              type: res.status == "0" ? "success" : "error",
              message: res.message ? res.message : "操作失败",
            });
            if (res.status == 0) {
              this.$refs[
                  "categoryEditDialogFromRef"
                  ].resetFields();
              this.categoryEditDialogVisible = false;
              this.getData();
            }
          }).catch(() => {
            return;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除
    categoryDel(info) {
      if (info.delete == true) {
        this.$message({ type: "error", message: "已删除，无法继续操作", });
        return;
      }
      if (info.children && info.children.length > 0) {
        this.$message({ type: "error", message: "存在子分类，无法删除！", });
        return;
      }
      this.$confirm("删除后，将不可恢复", "确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post(
            "/miniapp/category/delete",
            info,
            3000,
            true,
            2
        ).then((ret) => {
          ret.status === "0"
              ? this.getData()
              : this.$message.error(ret.message || "刪除失败！");
        });
      }).catch(() => {
        return;
      });
    },
    //修改分类显示状态
    categoryChangehidden(info) {
      const params = {
        categoryId: info.categoryId,
        usable: info.usable,
      };
      this.$post(
          "/miniapp/category/changeHidden",
          params,
          3000,
          true,
          2
      ).then((res) => {
        this.$message({
          type: res.status == 0 ? "success" : "error",
          message: res.message ? res.message : "操作失败",
        });
      }).catch(() => {
        return;
      });
    },
  },
};
</script>
<style lang="less">
// .category1 {
//     .el-switch.is-checked .el-switch__core {
//         background-color: #c0ccda !important;
//         border-color: #c0ccda !important;
//     }
// }
</style>
